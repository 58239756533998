
@font-face {
  font-family: skinny;
  src: url("assets/font/SKINNY.TTF");
}


@font-face {
  font-family: zx;
  src: url("assets/font/ZX_SPECTRUM-7.TTF");
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  font-family: skinny;
  color: #fff;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
html, body { touch-action: none }
body {
  background: #000;
  font-weight: 400;
  overflow-y: scroll;
}

.App{
  font-family: skinny;
  max-width: 1350px;
  width: 80%;
  margin: 0 auto;
}
ol, ul {
  list-style: none;

  font-weight:normal;
}
li{
  font-weight:normal;

}
blockquote, q {
  quotes: none;
}

@media (max-width: 600px) {
  .App{width:96%}
}


